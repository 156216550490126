import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import axios from 'axios'
import VueFormulate from '@braid/vue-formulate'
import S3UploaderPlugin from './s3UploaderPlugin'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss'

Vue.config.productionTip = false

Vue.use(VueFormulate, {
  uploadUrl: 'https://y2337fzkc4.execute-api.us-west-1.amazonaws.com/getSignedUrl',
  plugins: [S3UploaderPlugin],
})

Vue.use(VueToast, {
  position: 'top',
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
