<template>
  <div class="thanks">
    <h2 v-if="submissions[0]">Thank You, {{ submissions[0].name }}</h2>
    <p>
      If you would like, select your favorite photo by clicking on one below!
    </p>

    <div v-if="submissions[0]" class="photos">
      <ul
        v-for="(user, uIndex) in submissions"
        :key="user._id"
        class="photo-list"
      >
        <li
          class="image-preview"
          v-for="(photo, pIndex) in user.userPhotos"
          :key="photo.url"
        >
          <img
            :src="photo.url | thumbUrl"
            :alt="photo.name"
            @click="selectFav(photo, uIndex, pIndex)"
            :class="{ fav: fav.url == photo.url || photo.fav === true }"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Thanks",

  data() {
    return {
      submissions: "",
      fav: "",
    };
  },

  async created() {
    const { data } = await axios.get(
      `/.netlify/functions/api/${this.$route.params.userEmail}`
    );
    this.submissions = data;
    this.submissions.forEach((submission, uIndex) => {
      submission.userPhotos.forEach((photo, pIndex) => {
        if (photo.fav === true) {
          this.fav = {
            ...photo,
            userIndex: uIndex,
            photoIndex: pIndex,
          };
        }
      });
    });
  },

  filters: {
    thumbUrl(oriUrl) {
      const thumbUrl = oriUrl.replace(
        "https://sonia-strong",
        "https://sonia-strong-thumbnails"
      );
      return thumbUrl;
    },
  },

  methods: {
    selectFav(photo, userIndex, photoIndex) {
      if (this.fav.url == photo.url) {
        this.fav = "";
        this.$toast.open({
          message: "You have DESELECTED your favorite photo",
          type: "warning",
          duration: 1000000,
        });
      } else {
        this.fav = {
          ...photo,
          userIndex,
          photoIndex,
        };
        this.$toast.open("Your favorite photo has been selected!");
      }
    },
  },

  watch: {
    fav: async function (newFav, oldFav) {
      if (newFav === "") {
        // Deselecting fav
        this.submissions[oldFav.userIndex].userPhotos[
          oldFav.photoIndex
        ].fav = false;

        await axios.put(
          `/.netlify/functions/api/${this.submissions[oldFav.userIndex]._id}`,
          this.submissions[oldFav.userIndex].userPhotos
        );
      } else if (oldFav === "") {
        // No Favs have been selected yet
        this.submissions[newFav.userIndex].userPhotos[
          newFav.photoIndex
        ].fav = true;

        await axios.put(
          `/.netlify/functions/api/${this.submissions[newFav.userIndex]._id}`,
          this.submissions[newFav.userIndex].userPhotos
        );
      } else {
        // Selecting new Fav
        this.submissions[oldFav.userIndex].userPhotos[
          oldFav.photoIndex
        ].fav = false;
        this.submissions[newFav.userIndex].userPhotos[
          newFav.photoIndex
        ].fav = true;

        await axios.put(
          `/.netlify/functions/api/${this.submissions[oldFav.userIndex]._id}`,
          this.submissions[oldFav.userIndex].userPhotos
        );
        await axios.put(
          `/.netlify/functions/api/${this.submissions[newFav.userIndex]._id}`,
          this.submissions[newFav.userIndex].userPhotos
        );
      }
    },
  },
};
</script>

<style lang="sass" scoped>
h2
  font-size: 34px
  margin-bottom: 5px
p
  margin-top: 0

.photos
  max-width: 1400px
  margin: 60px auto

.photo-list
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
  grid-auto-flow: dense
  gap: 40px
  list-style: none
  margin: 0
  padding: 20px

.image-preview
  img
    padding: 5px
    max-width: 100%

    &:hover
      cursor: pointer

.fav
  border: 3px solid #41b983
  border-radius: 5px
</style>

<style lang="sass">
.v-toast__text
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
</style>
