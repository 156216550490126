<template>
  <main class="home">
    <section class="video">
      <video
        id="my-player"
        class="video-js vjs-16-9"
        controls
        preload="auto"
        width="100%"
        data-setup='{
          "timelineHoverPreviews": true,
          "plugins": {
            "mux": {
              "debug": false,
              "data":{
                "env_key": "48ms61r71h9od7970spssdh60",
                "video_title": "Sonia Memorial Slideshow"
              }
            }
          }
        }'
      >
        <source
          src="01hXJM1AxFoZkH100qxbzkGn7AOJePDZmdTN2yiCGXuUY"
          type="video/mux"
        />
      </video>

      <div class="vid-info">
        <a
          download="Sonia Memorial Slideshow"
          class="dl-link"
          href="https://sonia-strong.s3.us-west-1.amazonaws.com/download/Slideshow.mp4"
          >Download</a
        >
      </div>
    </section>
    <!-- <section class="intro">
      <p>
        The Molina's would love to gather fond memories of Sonia. Please use the
        form below to submit your <strong>top 10 photos</strong>. After
        submitting, you can select your favorite to be added to her memorial
        slideshow. 💙
      </p>
    </section>
    <section class="sonia-form">
      <FormulateForm
        v-model="formValues"
        @submit="sendData"
        #default="{ isLoading, hasErrors }"
      >
        <FormulateInput
          type="text"
          name="name"
          label="Your name"
          validation="required"
          validation-behavior="live"
        />
        <FormulateInput
          type="text"
          name="email"
          label="Email Address"
          validation="required|email"
          validation-behavior="live"
        />

        <FormulateInput
          type="image"
          name="userPhotos"
          label="Click below, or drag and drop images below"
          help="Select a png, jpg or gif to upload."
          upload-behavior="delayed"
          multiple
        />

        <FormulateInput
          type="submit"
          :label="isLoading ? 'Loading... This might take a minute' : 'Submit'"
          :disabled="(isLoading, hasErrors)"
        />
      </FormulateForm>
    </section> -->
  </main>
</template>

<script>
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import videojs from "@mux/videojs-kit";
import "@mux/videojs-kit/dist/index.css";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      formValues: {},
      fav: "",
    };
  },
  methods: {
    // async uploadFile() {
    //   if (this.images) {
    //     try {
    //       // eslint-disable-next-line no-unused-vars
    //       const path = await this.images.upload();
    //     } catch (err) {
    //       alert("Error uploading");
    //       console.error(err);
    //     }
    //   }
    // },

    async sendData(item) {
      // eslint-disable-next-line no-unused-vars
      const { data } = await axios.post("/.netlify/functions/api", item);

      this.$router.push({
        name: "Thanks",
        params: { userEmail: this.formValues.email },
      });
    },
  },
};
</script>

<style lang="sass">
.video-js .vjs-big-play-button
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.vid-info
  text-align: right

.dl-link
  font-size: 10px
  font-weight: 600
  text-transform: uppercase
  margin-top: 16px
  text-align: right
  text-decoration: none
  color: #0b2567

.home
  margin-top: 40px
  max-width: 1200px
  margin: 0 auto

.formulate-form
  max-width: 350px
  margin: 0 auto
  text-align: left

.formulate-input-label
  margin: 0 16px

#formulate---3_label
  text-align: center

#formulate---3-help
  text-align: center

#formulate---4
  display: block
  width: 100%

.formulate-input .formulate-input-element
  margin: 0 auto
</style>