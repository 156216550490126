<template>
  <div class="thanks">
    <div v-if="submissions[0]" class="photos">
      <ul
        v-for="(user, uIndex) in submissions"
        :key="user._id"
        class="photo-list"
      >
        <h2>{{ user.name }}</h2>
        <li
          class="image-preview"
          v-for="(photo, pIndex) in user.userPhotos"
          :key="photo.url"
        >
          <img
            :src="photo.url"
            :alt="photo.name"
            @click="download(photo, uIndex, pIndex)"
            :class="{ fav: fav.url == photo.url || photo.fav === true }"
          />
          <a :href="photo.url">Download</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Thanks",

  data() {
    return {
      submissions: "",
      fav: "",
    };
  },

  async created() {
    const { data } = await axios.get(`/.netlify/functions/api/`);
    this.submissions = data;
    // this.submissions.forEach((submission, uIndex) => {
    //   submission.userPhotos.forEach((photo, pIndex) => {
    //     if (photo.fav === true) {
    //       this.fav = {
    //         ...photo,
    //         userIndex: uIndex,
    //         photoIndex: pIndex,
    //       };
    //     }
    //   });
    // });
  },
};
</script>

<style lang="sass" scoped>
h2
  font-size: 34px
  margin-bottom: 5px
p
  margin-top: 0

.photos
  max-width: 1400px
  margin: 60px auto

.photo-list
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
  grid-auto-flow: dense
  gap: 40px
  list-style: none
  margin: 0
  padding: 20px

.image-preview
  img
    padding: 5px
    max-width: 100%

    &:hover
      cursor: pointer

.fav
  border: 3px solid #41b983
  border-radius: 5px
</style>

<style lang="sass">
.v-toast__text
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
</style>
